import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"

const ChangeRequestPage = ( {data} ) => {
  console.log(data)
  return (
    <>
      <Seo title="Change Request" />
      <section className="container flex flex-col gap-8 p-8 mt-32 lg:flex-row lg:gap-40 md:p-16">

        <div className="flex flex-col w-full gap-8 lg:w-1/3">
        <h1 className="mb-8">Request a Change</h1>
        <p>Request a change here.</p>
        <p>You can also submit feedback using this form too!</p>
        
        <p className="text-xs">For amending multiple contacts/complex changes/multiple photos, please email <a className="text-primary-default hover:opacity-70" href="mailto:dan.farrow@adtrak.co.uk">dan.farrow@adtrak.co.uk</a>.</p>
        </div>

        <form
          id="form"
          action="https://formspree.io/f/xayajpyd"
          method="POST"
          className="flex flex-col w-full lg:w-2/3"
          encType="multipart/form-data"
        >
          <label className="flex flex-col w-full gap-4 mb-16 sm:items-center sm:flex-row">
            <span className="w-1/4">Your Name*</span>
            <input
              required
              type="text"
              className="w-3/4"
              name="Your Name"
              placeholder="e.g. Jane Smith"
            />
          </label>
          <label className="flex flex-col w-full gap-4 mb-16 sm:items-center sm:flex-row">
            <span className="w-1/4">Your Email*</span>
            <input
              required
              type="text"
              className="w-3/4"
              name="_replyto"
              placeholder="e.g. jane.smith@adtrak.co.uk"
            />
          </label>
          <div className="w-full mb-2">I want to...</div>
          <label className="w-full">
            <input type="checkbox" name="Change Type" value="Add a new contact" /> Add a new contact
          </label>
          <label className="w-full">
            <input type="checkbox" name="Change Type" value="Remove a contact" /> Remove a contact
          </label>
          <label className="w-full">
            <input type="checkbox" name="Change Type" value="Change my contact data" /> Change my contact data
          </label>
          <label className="w-full">
            <input type="checkbox" name="Change Type" value="Change someone else's data" /> Change someone else's contact data
          </label>
          <label className="w-full">
            <input type="checkbox" name="Change Type" value="Feedback" /> Supply feedback
          </label>
          <div className="grid grid-cols-1 gap-8 my-12 md:grid-cols-2">
          <label className="flex flex-col w-full gap-4">
            <span className="">Name</span>
            <input
              type="text"
              className=""
              name="Name"
              placeholder="e.g. Justin Case"
            />
          </label>
          <label className="flex flex-row items-center w-full gap-4 pt-12">
            <input
              type="checkbox"
              className=""
              name="Manager"
            /> 
            <span className="">Are they a manager?</span>
          </label>
          <label className="flex flex-col w-full gap-4">
            <span className="">Job Title</span>
            <input
              type="text"
              className=""
              name="Job Title"
              placeholder="e.g. Digital Marketing Executive (SEO)"
            />
          </label>
          <label className="flex flex-col w-full gap-4">
            <span className="">Team</span>
            <select name="Team">
              <option value="N/A">Not applicable</option>
            {data.teams.edges.map((team, key) => (
              <option
                key={key}
                value={team.node.title}
              >
              {team.node.title}
              </option>
            ))}
            </select>
          </label>
          <label className="flex flex-col w-full gap-4">
            <span className="">Line Manager</span>
            <select name="Discipline">
              <option value="N/A">Not applicable</option>
            {data.contacts.edges.map((contact, key) => (
              <option
                key={key}
                value={contact.node.name}
              >
              {contact.node.name}
              </option>
            ))}
            <option value="Other">- Other</option>
            </select>
          </label>
          <label className="flex flex-col w-full gap-4">
            <span className="">Email</span>
            <input
              type="text"
              className=""
              name="Email"
              placeholder="e.g. justin.case@adtrak.co.uk"
            />
          </label>
          <label className="flex flex-col w-full gap-4">
            <span className="">Direct Line</span>
            <input
              type="text"
              className=""
              name="Direct Line"
              placeholder="e.g. 0115 898 0000"
            />
          </label>
          <label className="flex flex-col w-full gap-4">
            <span className="">Discipline</span>
            <select name="Discipline">
              <option value="N/A">Not applicable</option>
            {data.disciplines.edges.map((discipline, key) => (
              <option
                key={key}
                value={discipline.node.title}
              >
              {discipline.node.title}
              </option>
            ))}
            </select>
          </label>
          <label className="flex flex-col w-full gap-4">
            <span className="">Working Days & Hours <span className="block text-2xs">(please supply their rough working days & hours)</span></span>
            <input
              type="text"
              className=""
              name="Working Days & Hours"
              placeholder="e.g. Mon - Fri, 9 - 5:30"
            />
          </label>
          <label className="flex flex-col w-full gap-4 ">
            <span className="">Slack ID <span className="block text-2xs">(view profile in Slack, click More and copy the member ID)</span></span>
            <input
              type="text"
              className=""
              name="slack_id"
              placeholder="e.g. UMAKT2WEP"
            />
          </label>
          </div>
          <label className="w-full mt-8">
            <span className="block mb-2">Further Details</span>
            <textarea
              placeholder="Anything you'd like to change, feedback to submit or just say hello"
              name="Details"
              className="w-full h-56 p-4"
            ></textarea>
          </label>
          <label className="flex flex-col w-full gap-4 mt-16 sm:items-center sm:flex-row">
            <span>Profile picture:</span>
            <input type="file" className="" aria-label="Upload a file" name="upload" />
          </label>
          <div className="w-full">
            <input
              type="submit"
              className="px-6 py-3 mt-16 text-black text-white transition duration-300 rounded-full cursor-pointer bg-primary-default hover:bg-primary-dark"
              value="Send change request"
            />
          </div>
        </form>
      </section>
    </>
  )
}

export default ChangeRequestPage

export const query = graphql`
  query RACQuery {
    teams: allDatoCmsTeam(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          title
        }
      }
    }
    disciplines: allDatoCmsDiscipline(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    contacts: allDatoCmsContact(
      filter: {manager: {eq: true}}
      sort: {order: ASC, fields: [name]}
    ) {
      edges {
        node {
          name
        }
      }
    }
  }
`